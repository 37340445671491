import React, { useCallback, useEffect } from 'react'
import { Menu } from 'antd';
import { useValues, useActions } from 'kea'
import { home_logic } from 'src/logic/home/home_logic'
import { SimpleIconFont } from 'src/components/Simple/SimpleIconFont'
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash'
import css from './index.module.less'
import classnames from 'classnames';
import { useMemoizedFn } from 'ahooks';

export const HomeMenus = ({ collapsed }: { collapsed: boolean }) => {
  const { menus, flatten_menu, default_open_keys, default_selected_keys } = useValues(home_logic);
  const {
    set_default_selected_keys,
    set_default_open_keys
  } = useActions(home_logic);

  const location = useLocation();
  const navigate = useNavigate();

  // 将数据处理成渲染需要的格式
  const recursive_process_menu = (list: any[], layer = 0) => {
    list.forEach(item => {
      const hasChildren = item.children && item.children.length > 0;
      item.key = item.id;
      item.label = <span className={css[`menu-layer${layer}`]}>{item.name}</span>;
      if (item.icon) {
        item.icon = <SimpleIconFont type={item.icon} />;
      }
      if (hasChildren) {
        recursive_process_menu(item.children, layer + 1);
      }
    });
    return list;
  };

  // 根据 pathname 找到对应的菜单项目
  const find_Menu_item = useMemoizedFn((location: any) => {
    const pathname = location.pathname as string;

    // 找到当前的
    const select = flatten_menu.find((d: any) => {
      if (d.module_path) {
        return d.module_path !== '/' && (d.module_path === pathname || pathname.includes(d.module_path));
      } else if (d.id === 1 && pathname === '/') {
        return true;
      } else {
        return null;
      }
    });

    if (select) {
      // 找到它所有的父亲
      let current = select;
      let doing = true;
      const openKeys: any[] = [];
      while (doing) {
        // eslint-disable-next-line no-loop-func
        const pItem = flatten_menu.find(d => d.id === current.pid);
        if (pItem) {
          openKeys.push(pItem);
          current = pItem;
        } else {
          doing = false;
        }
      }
      return {
        selectedKeys: [`${select.id}`],
        openKeys: openKeys.map(d => `${d.id}`)
      };
    } else {
      return {
        selectedKeys: [],
        openKeys: []
      };
    }
  })

  // 选中了某一个菜单项
  const handle_selected_nav_item = useCallback((payload: any) => {
    set_default_selected_keys([`${payload.key}`]);
    const next = flatten_menu.find(d => `${d.id}` === `${payload.key}`);
    const module_path = next.module_path;
    navigate(module_path);
  }, [default_open_keys, default_open_keys, flatten_menu]);


  const items = recursive_process_menu(cloneDeep(menus));

  useEffect(() => {
    const { selectedKeys, openKeys } = find_Menu_item(location);
    if (selectedKeys.length === 0 && openKeys.length === 0) {
      // set_default_selected_keys(['4'])
      // set_default_open_keys(['3'])
    }
  }, [flatten_menu]);

  useEffect(() => {
    const { selectedKeys, openKeys } = find_Menu_item(location);
    if (selectedKeys.length !== 0 && openKeys.length !== 0) {
      set_default_selected_keys(selectedKeys)
      set_default_open_keys(openKeys)
    }
  }, [flatten_menu]);

  return (
    <div className={classnames(css['home-menus-container'])}>
      <Menu
        mode="inline"
        items={items}
        inlineIndent={16}
        openKeys={default_open_keys}
        defaultOpenKeys={default_open_keys}
        defaultSelectedKeys={default_selected_keys}
        selectedKeys={default_selected_keys}
        onOpenChange={(payload) => {
          set_default_open_keys(payload)
        }}
        onSelect={(payload) => {

        }}
        onClick={(payload) => {
          handle_selected_nav_item(payload)
        }}
      />
    </div>
  )
}