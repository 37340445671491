import { kea } from 'kea'
import { toast } from 'react-hot-toast'
import {
  get_user_list_async,
  update_user_expiration_time_async,
  create_user_async
} from 'src/api/api.user'
import type { user_list_logicType } from "../../logic_types/src/logic/user/user_list_logicType";

export const init_filters = {
  mobile: '', // 手机号模糊查询
  account: '',// 账号模糊查询
  page_num: 1,
  page_size: 10,
  total: 0
}


export const user_list_logic = kea<user_list_logicType>({
  path: ["src", "logic", "user", "user_list_logic"],

  actions: () => ({
    update_filters: (value) => ({ value }),
    get_user_list: (value) => ({ value }),
    get_user_list_success: (value) => ({ value }),
    get_user_list_success_failure: (value) => ({ value }),
    update_page_num: (value) => ({ value }),
    update_page_size: (value) => ({ value }),
    update_edit_user_modal_visible: (value) => ({ value }),
    update_user_expiration_time: (value) => ({ value }),
    update_user_expiration_time_success: (value) => ({ value }),
    update_user_expiration_time_failure: (value) => ({ value }),
    update_create_user_modal_visible: (value) => ({ value }),
    create_user_success: (value) => ({ value }),
    create_user: (value) => ({ value }),
    create_user_failure: (value) => ({ value })
  }),

  reducers: {
    filters: [
      init_filters, {
        update_filters: (state, payload) => {
          return payload.value
        },
        reload_filters: () => {
          return init_filters
        },
        get_user_list_success: (state, payload) => {
          return { ...state, total: payload.value.total };
        },
        update_page_num: (state, payload) => {
          return { ...state, page_num: payload.value }
        },
        update_page_size: (state, payload) => {
          return { ...state, page_size: payload.value }
        }
      }
    ],
    user_list: [
      [],
      {
        get_user_list_success: (state, payload) => {
          return payload.value.list;
        }
      }
    ],
    loading: [
      false,
      {
        get_user_list: () => true,
        get_user_list_success: () => false,
        get_user_list_success_failure: () => false
      }
    ],
    edit_user_modal_visible: [
      false,
      {
        update_edit_user_modal_visible: (state, payload) => {
          return payload.value.status
        }
      }
    ],
    edit_user: [
      {},
      {
        update_edit_user_modal_visible: (state, payload) => {
          return payload.value.user
        }
      }
    ],
    edit_user_loading: [
      false,
      {
        update_user_expiration_time: () => true,
        get_user_list_success: () => false,
        update_user_expiration_time_failure: () => false
      }
    ],
    create_user_modal_visible: [
      false,
      {
        update_create_user_modal_visible: (state, payload) => {
          return payload.value
        }
      }
    ],
    create_user_loading: [
      false,
      {
        create_user: () => true,
        create_user_success: () => false,
        create_user_failure: () => false
      }
    ]
  },

  listeners: ({ actions, values }) => ({
    // 获取用户列表
    get_user_list: async () => {
      try {
        const filters = values.filters;
        const user_list_response = await get_user_list_async(filters);
        if (user_list_response.data) {
          actions.get_user_list_success(user_list_response.data)
        }
      } catch (err) {
        toast.error("获取用户列表失败");
        actions.get_user_list_success_failure(false);
      }
    },
    // 更新用户会员过期时间
    update_user_expiration_time: async (params) => {
      try {
        const update_user_expiration_time_response: any = await update_user_expiration_time_async(params.value);
        if (update_user_expiration_time_response.message === 'ok') {
          actions.update_user_expiration_time_success(update_user_expiration_time_response.data)
          actions.update_edit_user_modal_visible({ user: {}, status: false })
          actions.get_user_list(true);
        } else {
          actions.update_user_expiration_time_success(update_user_expiration_time_response.data)
          actions.update_edit_user_modal_visible({ user: {}, status: false })
          throw new Error("server error");
        }
      } catch (err) {
        toast.error("更新用户会员过期时间失败");
        actions.update_user_expiration_time_failure(false)
      }
    },
    // 创建用户
    create_user: async (params) => {
      try {
        const create_user_response: any = await create_user_async(params.value);
        if (create_user_response.message === 'ok') {
          actions.create_user_success(create_user_response.data);
          actions.update_create_user_modal_visible(false);
          actions.get_user_list(true);
        } else {
          throw new Error("")
        }
      } catch (err) {
        toast.error("创建用户失败,请查看用户是否已经存在");
        actions.create_user_failure(false);
      }
    }
  })
})